import {deleteAccountRequest} from "../graphql/mutations";
import React, {useContext, useState} from "react";
import {ClientContext} from "../hook/clientContextProvider";

export const DeleteAccountForm = () => {
    const { client } = useContext(ClientContext);
    const [isSubmit, setIsSubmit] = useState(false);
    const [email, setEmail] = useState("");
    const [reason, setReason] = useState("");
    const handleSubmit = async (email, reason) => {
        await client.graphql({
            query: deleteAccountRequest,
            variables: {
                email: email,
                reason: reason
            },
            authMode: "iam"
        })
        setIsSubmit(true);
    }
    return (
        <div className="container">
            <div className="row">
                <div className="d-flex justify-content-center align-items-center" style={{paddingBottom: "15px", paddingTop: "30px"}}>
                    <h1 className="display-1">Delete Account Form</h1>
                </div>
                <div className="alert alert-warning" role="alert">

                    Before deleting your account, please make sure to read the following instructions:
                    <p>1. Please ensure that you have canceled any subscription services to avoid automatic renewal. </p>
                    <p>2. Deleting your account will result in the permanent removal of all associated data, including previously generated images.</p>
                    <p>3. The account deletion process may take approximately 1 day.</p>
                </div>
                <div className="d-flex" style={{paddingBottom: "15px", paddingTop: "30px"}}>
                    <div className="d-flex" style={{marginRight: "30px"}}>
                        <label>Email: </label>
                    </div>
                    <div className="d-flex">
                        <input type="text" id="email" name="email" required minLength="4" maxLength="30" size="30" onChange={(event) => setEmail(event.target.value)} onInput={(event) => setEmail(event.target.value)}/>
                    </div>
                </div>
                <div className="d-flex" style={{paddingBottom: "15px", paddingTop: "30px"}}>
                    <div className="d-flex" style={{marginRight: "30px"}}>
                        <label>Feedback: </label>
                    </div>
                    <div className="d-flex">
                        <textarea maxLength={1000} placeholder={"We are sad to see you leave... Please let us know how we can do better to keep you here"} rows={4} cols={40} onChange={(event) => setReason(event.target.value)}/>
                    </div>
                </div>
                {isSubmit && (<div className="alert alert-success" role="alert">
                    We have received your request.
                </div>)}
                <button className="btn btn-outline-dark btn-block tap-button" onClick={() => handleSubmit(email, reason)} >Submit</button>
            </div>
        </div>
    )
}