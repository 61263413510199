import styled from "styled-components";

const Diamond = styled.div`
  width: ${({ size }) => size};
  aspect-ratio: 1;
  clip-path: polygon(
          50% 0%, 100% 50%, 50% 100%, 0% 50%
  );
  overflow: hidden;
  display: inline-block;
  position: relative;
  background-color: white;
`;

// Styled img inside the hexagon
const DiamondImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

const DiamondImage = ({ src, size = '50px' }) => {
    return (
        <div>
            <Diamond size={size}>
                <DiamondImg src={src} />
            </Diamond>
        </div>
    );
};


export default DiamondImage;

