import { Amplify } from 'aws-amplify';
import React from "react";

import amplifyconfig from './amplifyconfiguration.json';
import './App.css';
import {createBrowserRouter, Link, RouterProvider} from "react-router-dom";
import {Privacy} from "./components/privacy";
import {Terms} from "./components/terms";
import {Home} from "./components/home";
import {Footer} from "./components/footer";
import {ClientContextProvider} from "./hook/clientContextProvider";
import {DeleteAccountForm} from "./components/delete-account-form";

Amplify.configure({
    API: {
        GraphQL: {
            endpoint: 'https://qawtkog3vrh2bdsv2orphaprlu.appsync-api.us-west-2.amazonaws.com/graphql',
            region: 'us-west-2',
            defaultAuthMode: 'iam',
        }
    },
    ...amplifyconfig
});


function App() {
    const backgroundStyle = {
        backgroundImage: "url('back.png')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };
    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <div className="d-flex flex-column min-vh-100" style={backgroundStyle}>
                    <div className="flex-grow-1">
                        <Home />
                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            ),
        },
        {
            path: "privacy",
            element: (
                <div className="d-flex flex-column min-vh-100" style={backgroundStyle}>
                    <div className="flex-grow-1">
                        <Privacy />
                    </div>
                </div>
            )
        },
        {
            path: "terms",
            element: <div className="d-flex flex-column min-vh-100" style={backgroundStyle}>
                <div className="flex-grow-1">
                    <Terms />
                </div>
            </div>
        },
        {
            path: "request-delete",
            element: (
                <div className="d-flex flex-column min-vh-100" style={backgroundStyle}>
                    <div className="flex-grow-1">
                        <DeleteAccountForm />
                    </div>
                </div>
            )
        }
    ]);
  return (
      <ClientContextProvider>
          <RouterProvider router={router} />
      </ClientContextProvider>

  );
}

export default App;
