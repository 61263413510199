import React from "react";
import './style.css';
import styled from "styled-components";
import DiamondImage from "./shape-image";

const Card = styled.div`
  display: flex;
  padding: 15px;
  align-items: center;
  margin-top: 15px; 
  border-radius: 15px; 
  background-color: #F8E6F7;
  flex-direction: column;
`;

export const Home = () => {
    return (
        <>
            <div className="container" style={{marginBottom: 15}}>
                <div className="row">
                    <div className="d-flex justify-content-center align-items-center" style={{paddingBottom: 15, paddingTop: 30}}>
                        <h1 className="display-1">MadeYu - AI Art Generator</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="d-flex justify-content-center">
                        <a href="https://pse.is/568vgb" target="_blank" rel="noopener noreferrer">
                            <img src="madeyu-logo.png" height="128px" width="128px" className="img-fluid rounded-circle"/>
                        </a>
                    </div>
                    <div className="d-flex justify-content-center" style={{marginTop: 15}}>
                        <a href="https://pse.is/568vgb" target="_blank" rel="noopener noreferrer">
                            <img src="app-download-icon.svg" alt="MadeYu - AI Art Generator" className="img-fluid" width="256px"/>
                        </a>
                    </div>
                    <div className="d-flex justify-content-center" style={{marginTop: 15}}>
                        <a href="https://play.google.com/store/apps/details?id=com.madeyu.ai.ReactNativeMadeYuApp" target="_blank" rel="noopener noreferrer">
                            <img src="google-play-download-icon.svg" alt="MadeYu - AI Art Generator" className="img-fluid" width="296px"/>
                        </a>
                    </div>
                    {/*col-lg-5 col-md-5 col-sm-12 col-12*/}
                    <div style={{height: 15, borderRadius: 15, background: "#DEC0C0"}} />
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <Card >
                            <h5 className="d-flex align-items-center" style={{paddingTop: 50, paddingBottom: 50}}>
                                MadeYu is an AI image generation application that allows users to input text prompts or assist with images, turning them into AI-generated images of various styles. It offers a variety of real-world and anime-style options for users to choose from.
                            </h5>
                        </Card>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <Card>
                            <h1 className="d-flex">Text to Image</h1>
                            <div className="d-flex">
                                <DiamondImage size={"150px"} src="image1.png"/>
                                <h5 className="d-flex align-items-center">
                                    To use text prompts in MadeYu, simply type a description of what you want to generate. The AI will interpret your prompt and create an image based on your input. Be as detailed or creative as you like—the more specific your prompt, the more tailored the output will be.
                                </h5>
                            </div>
                        </Card>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <Card>
                            <h1 className="d-flex">Image to Image</h1>
                            <div className="d-flex">
                                <h5 className="d-flex align-items-center" >
                                    To use the image-to-image feature in MadeYu, upload an image you want to transform. The AI will analyze it and generate a new version based on your input. You can add text prompts for more specific results or let the AI creatively reimagine the image.
                                </h5>
                                <DiamondImage size={"150px"} src="image2.png"/>
                            </div>
                        </Card>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <Card>
                            <h1 className="d-flex">Inpaint</h1>
                            <div className="d-flex">
                                <DiamondImage size={"150px"} src="image3.png"/>
                                <h5 className="d-flex align-items-center">
                                    To use the inpaint feature in MadeYu, upload an image and select the area you want to edit. The AI will fill in the selected area based on your description, seamlessly blending it with the rest of the image.
                                </h5>
                            </div>
                        </Card>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <Card>
                            <h1 className="d-flex">Details Control</h1>
                            <div className="d-flex">
                                <h5 className="d-flex align-items-center" >
                                    To use the detail control feature in MadeYu, you can adjust specifics like facial expression, body expression, and image ratio. This allows you to fine-tune the details and get exactly the result you want.
                                </h5>
                                <DiamondImage size={"150px"} src="image4.png"/>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}
