import React, {createContext} from "react";
import { generateClient } from "aws-amplify/api";

export const ClientContext = createContext();

export const ClientContextProvider = (props) => {
    const client = generateClient();
    return (
        <ClientContext.Provider value={{client}}>
            {props.children}
        </ClientContext.Provider>
    );
}
