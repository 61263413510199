import React from "react";
import './style.css';
import {Link} from "react-router-dom";
export const Footer = () => {
    return (
        <footer className="bg-dark text-light text-center py-3">
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <Link to="privacy" className="text-light">Privacy Policy</Link>
                    </div>
                    <div className="col-6">
                        <Link to="terms" className="text-light">Terms of Use</Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}
