import React from "react";

export const Terms = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <strong style={{fontSize: 30}}>Terms &amp; Conditions</strong>
                    <br/>
                    <br/>
                    <text>Thank you for using MadeYu (the App/we/Yi-An Chen). By downloading or using the app, these terms will automatically apply to you/ user/ users – you should make sure, therefore, that you read them carefully before using the app. You’re not allowed to copy or modify the app, any part of the app, or our trademarks in any way. You’re not allowed to attempt to extract the source code of the app, and you also shouldn’t try to translate the app into other languages or make derivative versions. The app itself, and all the trademarks, copyrights, database rights, and other intellectual property rights related to it, still belong to Yi-An Chen</text>
                    <br/>
                    <br/>
                    <text>Yi-An Chen is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason.</text>
                    <br/>
                    <br/>
                    <text>The app stores and processes personal data that you have provided to us to provide our Service. It’s your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features, and it could mean that the app won’t work properly or at all.</text>
                    <br/>
                    <br/>
                    <text>These Terms of Service (the “Agreement”) explain what rights you have with respect to images and other assets which you might generate with the Service, or prompts you might enter into the Service (the “Assets”), your use of the Services, and other important topics like arbitration. Please read it carefully. Our privacy policy outlines how we handle your data here.</text>
                    <br/>
                    <br/>
                    <text>This Agreement is entered into by MadeYu/Yi-An Chen and the entity or person agreeing to these terms (the "user," “You” or “Your”) and governs the Customer's access to and use of the Services.</text>
                    <br/>
                    <br/>
                    <text>This Agreement is effective when the user is presented with this Agreement and proceeds to use the Services (the "Effective Date") or to receive or distribute Assets. These terms may be updated and presented again to the Customer from time to time. Continued use of the Services constitutes acceptance of the updated terms. If You do not agree to this Agreement, please stop using the Services.</text>
                    <br/>
                    <br/>
                    <text>Other documents referenced here may also bind the Customer’s use of the Services, including the Subscription Plans page and the Guidelines below.</text>
                    <br/>
                    <br/>
                    <strong style={{fontSize: 30}}>Age Requirements</strong>
                    <br/>
                    <br/>
                    <text>By accessing the Services, You confirm that You are at least 18 years old and meet the minimum age of digital consent in Your country. If You are old enough to access the Services in Your country, but not old enough to have authority to consent to our terms, Your parent or guardian must agree to our terms on Your behalf.
                        Please ask Your parent or guardian to read these terms with You. If You are a parent or legal guardian, and You allow Your teenager to use the Services, then these terms also apply to You and You are responsible for Your teenager’s activity on the Service</text>
                    <br/>
                    <br/>
                    <strong style={{fontSize: 30}}>Prohibited Conduct</strong>
                    <br/>
                    <br/>
                    <ul>
                        <li>As a user of this App/ MadeYu, you agree not to:</li>
                        <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
                        <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or this App/ MadeYu.</li>
                        <li>Use any resoueses obtained from this app/ MadeYu in order to harass, abuse, or harm another person.</li>
                        <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
                        <li>Use the Site in a manner inconsistent with any applicable laws or regulations.</li>
                        <li>Be kind and respect each other and yourself. Do not create images or use text prompts that are inherently disrespectful, aggressive, hateful, or otherwise abusive.</li>
                        <li>Please avoid sharing visually shocking or disturbing content.</li>
                        <li>You may not use MadeYu to generate images for political campaigns, or to try to influence the outcome of an election.</li>
                        <li>You may not use MadeYu for illegal activity nor may you upload images to our servers that involve illegal activity, or where the uploading itself may be illegal.</li>
                        <li>You may not intentionally mislead recipients of the Assets about their nature or source.</li>
                        <li>Respect others’ rights. Do not upload others’ private information.</li>
                        <li>Be careful about sharing. It’s OK to share Your creations outside of the App community but please consider how others might view Your content.</li>
                        <li>Any violations of these rules may lead to bans from our services. Behave respectfully or lose Your rights to use the Service.</li>
                    </ul>
                    <strong style={{fontSize: 30}}>Right to Terminate Accounts</strong>
                    <br/>
                    <br/>
                    <text>In the app, users have the right to delete their account. Once this action has been completed by the user, all content created by the user will be automatically eliminated. MadeYu also won't keep any information of the user who deleted their account. Please be careful before making
                        this action.
                    </text>
                    <br/>
                    <br/>
                    <strong style={{fontSize: 30}}>Ownership of Your Content</strong>
                    <br/>
                    <br/>
                    <text>By sharing your Contributions to any part of this app/ MadeYu, you automatically grant, and you represent and warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Contributions (including, without limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare derivative works of, or incorporate into other works, such Contributions, and grant and authorize sublicenses of the foregoing. The use and distribution may occur in any media formats and through any media channels.
                    </text>
                    <br/>
                    <br/>
                    <text>We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area on the Site. You are solely responsible for your Contributions to the Site and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.</text>
                    <br/>
                    <br/>
                    <text>We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Contributions; (2) to re-categorize any Contributions to place them the App; and (3) to pre-screen or delete any Contributions at any time and for any reason, without notice. We have no obligation to monitor your Contributions.
                    </text>
                    <br/>
                    <br/>
                    <strong style={{fontSize: 30}}>DMCA section</strong>
                    <br/>
                    <br/>
                    <text>Notification Procedures</text>
                    <br/>
                    <br/>
                    <text>We respect the intellectual property rights of others. If you believe that material located on or linked to by the Services violates your copyright or trademark, please send a notice of claimed infringement to madeyu.ai@gmail.com with the subject “Takedown Request,” and include the following:
                    </text>
                    <br/>
                    <br/>
                    <text>Your physical or electronic signature.
                        Identification of the copyrighted work (or mark) you believe to have been infringed or, if the claim involves multiple works, a representative list of such works.
                        Identification of the material you believe to be infringing in a sufficiently precise and detailed manner to allow us to locate that material.
                        Adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address).
                        A statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent, or the law.
                        A statement that the information in the written notice is accurate.
                        A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.
                    </text>
                    <br/>
                    <br/>
                    <text>Upon receipt of a notice that complies with the foregoing, we reserve the right to remove or disable access to the accused material or disable any links to the material; notify the party accused of infringement that we have removed or disabled access to the identified material; and terminate access to and use of the Services for any user who engages in repeated acts of infringement.
                    </text>
                    <br/>
                    <br/>
                    <strong style={{fontSize: 30}}>Payment Details</strong>
                    <br/>
                    <br/>
                    <text>We may invoice you for your use of the Services through a third-party payment service provider. The third-party service provider’s terms of service shall govern and supersede this Agreement in case of conflict.
                    </text>
                    <br/>
                    <br/>
                    <text>You are free to cancel your plan at any time. We also reserve the right to terminate your access to the Service for any reason, including illegal behavior or other inappropriate use of the Service. Any violation of these terms of service is a breach of this Agreement. You will not be refunded for the current subscription period, but you will not be charged after the current subscription period has ended.
                    </text>
                    <br/>
                    <br/>
                    <strong style={{fontSize: 30}}>Disclaimer of Warranty and Liability</strong>
                    <br/>
                    <br/>
                    <text>You should be aware that there are certain things that Yi-An Chen/ MadeYu will not take responsibility for. Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi or provided by your mobile network provider, but Yi-An Chen/ MadeYu  cannot take responsibility for the app not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data allowance left.
                    </text>
                    <br/>
                    <br/>
                    <text>If you’re using the app outside of an area with Wi-Fi, you should remember that the terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third-party charges. In using the app, you’re accepting responsibility for any such charges, including roaming data charges if you use the app outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you’re using the app, please be aware that we assume that you have received permission from the bill payer for using the app.
                    </text>
                    <br/>
                    <br/>
                    <text>Along the same lines, Yi-An Chen/ MadeYu cannot always take responsibility for the way you use the app i.e. You need to make sure that your device stays charged – if it runs out of battery and you can’t turn it on to avail the Service, Yi-An Chen/ MadeYu  cannot accept responsibility.
                    </text>
                    <br/>
                    <br/>
                    <text>With respect to Yi-An Chen/ MadeYu ’s responsibility for your use of the app, when you’re using the app, it’s important to bear in mind that although we endeavor to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. We accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.
                    </text>
                    <br/>
                    <br/>
                    <text>At some point, we may wish to update the app. The app is currently available on iOS – the requirements for the system(and for any additional systems we decide to extend the availability of the app to) may change, and you’ll need to download the updates if you want to keep using the app. Ian Chen does not promise that it will always update the app so that it is relevant to you and/or works with the iOS version that you have installed on your device. However, you promise to always accept updates to the application when offered to you, We may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.
                    </text>
                    <br/>
                    <br/>
                    <strong style={{fontSize: 30}}>Privacy policy</strong>
                    <br/>
                    <br/>
                    <text>We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the app, as well as data relating to your use of the app. However, we do not keep any personal information from the users. For more about privacy policy, please visit https://www.apple.com/legal/privacy/en-ww/.</text>
                    <br/>
                    <br/>
                    <strong style={{fontSize: 30}}>Right to Update or Modify Terms</strong>
                    <br/>
                    <br/>
                    <text>We  may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Terms and Conditions on this page.
                    </text>
                    <p>
                        These terms and conditions are effective as of 2024.01.30
                    </p>
                    <p><strong style={{fontSize: 30}}>Contact Us</strong></p>
                    <p>
                        If you have any questions or suggestions about my
                        Terms and Conditions, do not hesitate to contact me
                        at madeyu.ai@gmail.com
                    </p>
            </div>
            </div>
        </div>
    )
}